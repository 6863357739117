<template>
    <div class="bottom">
            <div class="bottom-1200">
                <div class="bottom-1200-left">
                    <div>
                        <span>联系我们：lj7203@126.com</span>
                        <span>学校地址：{{resData.dz}}</span>
                        <span>联系电话：{{resData.phone}}</span>
                    </div>
                    <div>
                       <span>传真：0511-87201822</span>
                        <span>备案号：{{resData.copyright}}</span>
                        <span>访问量：{{resData.totalviews}}</span>
                    </div>
                    <div>
                        <span @click="hrefUrl()" style="cursor: pointer;">网站地图</span>
                    </div>
                </div>
                <div class="bottom-1200-right">
                    <div>
                        <img src="static/jr/gzh.png" alt="" class="ewm">
                        <p class="ewm-text">微信公众号二维码</p>
                    </div>
                    <div>
                        <img src="static/jr/qywx.png" alt="" class="ewm">
                        <p class="ewm-text">企业微信公众号二维码</p>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import JrApi from '../../../lvya-common/src/api/eos/jr'
export default {
    components: {
    },
    data() {
        return {
            resData: '',
            count: '',
        }
    },
    created() {
        this.urlGet()
    },
    methods: {
        // 跳转二级页面
        hrefUrl(url) {
            this.$router.push({ path: '/map' })
        },
        async HF() {
            const resData = await JrApi.HF()
            this.resData = resData
            // console.log(resData)
        },
        async Count() {
            const resData = await JrApi.Count()
            this.resData = resData
            // console.log(this.resData)
        },
        urlGet() {
            this.index = this.$route.path
            // console.log(this.index)
            if (this.index == '/') {
                this.Count()
            }
        },
        // getPaging() {
        //     axios.post('http://192.168.0.102:8081/schoolsite/api/24/addCount', qs.stringify({}))
        //     .then(res => {
        //         this.count = res.data.data
        //         console.log(this.count)
        //         // console.log(this.list)
        //     })
        // },
    },
    mounted() {
        this.HF()
    },
}
</script>

<style scope>
.bottom{
    width: 100%;
    height: 140px;
    background: #8b1e23;
}
.bottom-1200{
    width: 1200px;
    margin: 0 auto;
    color: #fff;
    display: flex;
    justify-content: space-between;
    line-height: 30px;
    padding-top: 15px;
    font-size: 14px;
}
.bottom-1200-left{
    width: 65%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    text-align: left;
}
/* .bottom-1200-left div{
    width: 33%;
} */
.bottom-1200-left div span{
    display: block;
}
.bottom-1200-right{
    display: flex;
}
.bottom-1200-right div{
    text-align: center;
    margin-left: 20px;
}
.bottom-1200-right p{
    margin-block-start: 0em;
    margin-block-end: 0em;
}
.ewm{
    width: 80px;
    height: 80px;
    border-radius: 3px;
}
.ewm-text{
    text-align: center;
}
</style>
