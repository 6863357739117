<template>
  <div id="all" :class="{'hui': resData.ishui == 'Y'}">
    <Header></Header>
    <IndexHome></IndexHome>
    <Footer></Footer>
    <!-- 飘窗 -->
    <div :class="{'none': resData.ispc == 'N'}">
      <div id="adbox" ref="adbox" v-show="showAdbox" @mouseenter="enterbox" @mouseleave="leavebox">
        <a href="javascript:;">
            <img :src="resData.pcimg" alt="广告图片" @click="pcHrefUrl(resData.pcurl)" style="width:263px;height:142px;">
        </a>
        <b @click="closebox">×</b>
      </div>
    </div>
    <div class="mantle_div" v-if="isClosed">
      <p class="ztfw">暂停访问</p>
    </div>
  </div>
</template>

<script>

// 导入组件
import Header from '../components/header'
import Footer from '../components/footer'
import IndexHome from './index-home'
import JrApi from '../../../lvya-common/src/api/eos/jr'

export default {
  name: 'App',
  components: {
    IndexHome,
    Header,
    Footer
  },
  data() {
    return {
      resData: '',
      x: 263,
      y: 142,
      xin: true,
      yin: true,
      step: 1,
      delay: 10,
      itl: null,
      showAdbox: true,
      isClosed: '',
    }
  },
  created() {
    this.HF()
  },
  // 飘窗开始
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routeEnterShowAdbox()
    })
  },
  beforeRouteLeave(to, from, next) {
    next(vm => {})
    clearInterval(this.itl)
  },
  // 飘窗结束
  methods: {
    async HF() {
      const resData = await JrApi.HF()
      this.resData = resData
      this.isClosed = resData.state == 'N'
      console.log(resData, '1111')
      console.log(this.isClosed, '2222')
    },
    // 飘窗开始
    closebox() {
      this.showAdbox = false
      clearInterval(this.itl)
    },
    leavebox() {
      if (this.showAdbox === false) {
        clearInterval(this.itl)
      } else {
        this.itl = setInterval(this.movebox, this.delay)
      }
    },
    enterbox() {
      clearInterval(this.itl)
    },
    movebox() {
      var L = 0
      var T = 0
      var R = document.documentElement.clientWidth - this.$refs.adbox.offsetWidth
      var B = document.documentElement.clientHeight - this.$refs.adbox.offsetHeight
      this.$refs.adbox.style.left = this.x + document.documentElement.scrollLeft + 'px'
      this.$refs.adbox.style.top = this.y + document.documentElement.scrollTop + 'px'
      this.x = this.x + this.step * (this.xin ? 1 : -1)
      if (this.x < L) { this.xin = true; this.x = L }
      if (this.x > R) { this.xin = false; this.x = R }
      this.y = this.y + this.step * (this.yin ? 1 : -1)
      if (this.y < T) { this.yin = true; this.y = T }
      if (this.y > B) { this.yin = false; this.y = B }
    },
    routeEnterShowAdbox() {
      this.itl = setInterval(this.movebox, this.delay)
    },
    pcHrefUrl(url) {
      window.open(url, '_blank')
    }
  },
  mounted() {
  },
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  list-style: none;
}
.hui{
  -webkit-filter:grayscale(100%)
}
.none{
  display: none;
}
body {font-family: "Microsoft YaHei";}
#all {
	width: 100%;
	font-family: 'Microsoft YaHei', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	margin-top: 0px;
	font-size: 16px;
}
#adbox{
    width:263px;
    height:142px;
    position: absolute;
    z-index:999;
}
#adbox img{
    border: none;
}
#adbox b{
    position: absolute;
    right: 5px;
    top: 0;
    cursor: pointer;
    color:#fff;
}
.mantle_div{
    width: 100%;
    height: 100vh;
    background: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
  }
  .ztfw{
    line-height: 100vh;
    text-align: center;
    color: #fff;
    font-size: 22px;
  }
</style>
